<template>
  <div class="home">
    <v-container fluid class="py-0" style="background-color: #33312A">
      <v-row class="my-0" align="center">
        <v-col cols="7" class="py-0 px-0" v-if="!$vuetify.breakpoint.xs">
          <v-carousel height="calc(100vh - 64px)" hide-delimiters :show-arrows="false" cycle>
            <v-carousel-item v-for="(slide, sl) in slider.imagenes" :key="'slider-'+sl" :src="'https://content.elmurcielagotequileria.com/'+slide.path" transition="fade-transition"></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" v-if="$vuetify.breakpoint.xs" class="px-0 py-0">
          <v-carousel height="300" hide-delimiters :show-arrows="false" cycle>
            <v-carousel-item v-for="(slide, sl) in slider.imagenes" :key="'slider-'+sl" :src="'https://content.elmurcielagotequileria.com/'+slide.path" transition="fade-transition"></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col>
          <div class="text-center">
            <h1 style="color: #A06A4C">El Murciélago Tequilería</h1>
          </div>
          <div class="mx-auto mt-12 max-width-text">
            <p class="white--text">
              {{slider.descripcionElMurcielago}}
            </p>
          </div>
          <div class="my-8">
            <v-row no-gutters class="row-custom">
              <v-col cols="6" style="border-right: 1px solid white"></v-col>
              <v-col cols="6"></v-col>
            </v-row>
          </div>
          <div style="width: fit-content" class="mx-auto">
            <router-link to="/historia" style="color: #A06A4C;" class="not-decorated"><span class="big-titles mx-auto bold line-height-general">Continua con <br> nuestra historia</span></router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div style="background-color: #151616;">
      <v-container>
        <v-row>
          <v-col class="text-center white--text">
            <span class="d-block">Visita nuestras tiendas físicas,</span>
            <span class="d-block">te ayudamos a encontrar la botella ideal</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          
          <v-col cols="12" sm="4" class="white--text py-12">
            <v-img src="../assets/img/sucursal-valle.jpg" max-width="540"></v-img>
            <h1 class="text-center titulo-sucursal">Sucursal Valle</h1>

            <span class="d-block">Dirección</span>
            <span class="d-block">Río Moctezuma 225 L-3 Col. Del Valle 66220</span>
            <span class="d-block">San Pedro Garza García</span>
            <a href="https://goo.gl/maps/yRzHuivEnGna9m6A9" target="_blank" class="d-block white--text">Ver mapa</a>

            <v-row class="mt-3">
              <v-col class="white--text">
                <span class="d-block">Horario</span>
                <span class="d-block">Lunes a Viernes</span>
                <span class="d-block">11 am – 8 pm</span>
                <br>
                <span class="d-block">Sábados</span>
                <span class="d-block">11 am – 7 pm</span>
              </v-col>
              <v-col>
                <span class="d-block">Teléfono</span>
                <span class="d-block">81 1306 0718</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" class="white--text py-12">
            <v-img src="../assets/img/sucursal-colinas.jpg" max-width="540"></v-img>
            <h1 class="text-center titulo-sucursal">Sucursal Colinas</h1>

            <span class="d-block">Dirección</span>
            <span class="d-block">Plaza Vía 02 Blvd. Rogelio Cantú Gómez 1000</span>
            <span class="d-block">Las Colinas Residencial 64638 Monterrey</span>
            <a href="https://goo.gl/maps/wuMt5i5F4JMrgdsYA" target="_blank" class="d-block white--text">Ver mapa</a>

            <v-row class="mt-3">
              <v-col class="white--text">
                <span class="d-block">Horario</span>
                <span class="d-block">Lunes a Viernes</span>
                <span class="d-block">11 am – 8 pm</span>
                <br>
                <span class="d-block">Sábados</span>
                <span class="d-block">11 am – 7 pm</span>
              </v-col>
              <v-col>
                <span class="d-block">Teléfono</span>
                <span class="d-block">81 1360 7128</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" class="white--text py-12">
            <v-img src="../assets/img/elmurcielago-carretera.jpg" max-width="540"></v-img>
            <!-- <v-img src="../assets/img/sucursal-carretera.jpg" max-width="540"></v-img> -->
            <h1 class="text-center titulo-sucursal">Sucursal Carretera Nacional</h1>
  
            <span class="d-block">Dirección</span>
            <span class="d-block">Plaza Nacional, Carretera Nacional 5500</span>
            <span class="d-block">Villas La Rioja 64988 Monterrey NL</span>
            <a href="https://goo.gl/maps/q6UmQfr9LsuggHE28" target="_blank" class="d-block white--text">Ver mapa</a>
  
            <v-row class="mt-3">
              <v-col class="white--text">
                <span class="d-block">Horario</span>
                <span class="d-block">Lunes a Viernes</span>
                <span class="d-block">11 am – 8 pm</span>
                <br>
                <span class="d-block">Sábados</span>
                <span class="d-block">11 am – 7 pm</span>
              </v-col>
              <v-col>
                <span class="d-block">Teléfono</span>
                <span class="d-block">81 1357 3867</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" class="white--text py-12">
            <v-img src="../assets/img/sucursal-via-cordillera.jpg" max-width="540"></v-img>
            <h1 class="text-center titulo-sucursal">Sucursal Vía Cordillera</h1>
  
            <span class="d-block">Dirección</span>
            <span class="d-block">Residencial Colonia Villa, Eje Exterior #10, Alfonso Reyes, Av. Cordillera Local 28, Sin Nombre de Col 28, Sin Nombre de Col 25, 66196</span>
            <span class="d-block">Santa Catarina, N.L</span>
            <a href="https://maps.app.goo.gl/BBLy7esUwxbz1Gw29" target="_blank" class="d-block white--text">Ver mapa</a>
  
            <v-row class="mt-3">
              <v-col class="white--text">
                <span class="d-block">Horario</span>
                <span class="d-block">Lunes a Viernes</span>
                <span class="d-block">11 am – 8 pm</span>
                <br>
                <span class="d-block">Sábados</span>
                <span class="d-block">11 am – 7 pm</span>
              </v-col>
              <v-col>
                <span class="d-block">Teléfono</span>
                <span class="d-block">81 1103 5544</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>


      <div style="position: relative;">

        <v-container>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-img src="../assets/img/agave-hecho-tag-03.svg" class="mx-auto mb-5" width="110"></v-img>
              <v-img src="../assets/img/Composicion-TEQUILAS-HOME.jpg" width="350" class="mx-auto align-end">
              </v-img>
              <div style="transform: translateY(-50px)">
                <v-img src="../assets/img/tequila-ilus-blanco-04.svg" width="190" class="mx-auto" aspect-ratio="1" contain></v-img>
                <p class="white--text" style="transform: translateY(25px)">Desde su origen en el corazón del agave azul, el tequila ofrece una variedad más allá de lo inimaginable. Tenemos tequila de los 5 estados del país donde se produce.</p>
              </div>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-img src="../assets/img/agave-hecho-tag-03.svg" class="mx-auto mb-5" width="110"></v-img>
              <v-img src="../assets/img/Composicion-MEZCALES-HOME.jpg" width="350" class="mx-auto align-end">
              </v-img>
              <div style="transform: translateY(-50px)">
                <v-img src="../assets/img/mezcal-ilus-blanco-02.svg" width="190" class="mx-auto" aspect-ratio="1" contain></v-img>
                <p class="white--text" style="transform: translateY(25px)">Con la riqueza de poder ser elaborado de más de 34 diferentes tipos de agave, el mezcal es un elemento tan diverso como la cultura que representa.</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid class="py-0">
          <v-row v-if="instafeed.length > 0" class="my-0">
            <v-col class="pa-0">
                
                <div class="owl-carousel-custom" style="position: relative;">

                  <carousel :autoplay="false" :nav="false" :items="4" :dots="false" :responsive="{0:{items:1,margin:0},600:{items:4,margin:0}}" :loop="true">
                    <template v-for="(insta,i) in instafeed">
                      <a :href="insta.permalink" target="_blank" :key="'instagram-photo-'+i">
                        
                        <img :src="insta.media_url" class="instagram-feed-photo" />
                      </a>
                    </template>

                    <template slot="prev" class="ammm">
                        <span class="prev-icon"><v-icon size="36" color="#221F1F">mdi-chevron-left</v-icon></span>
                    </template>

                    <template slot="next" class="ammm">
                        <span class="next-icon"><v-icon size="36" color="#221F1F">mdi-chevron-right</v-icon></span>
                    </template>
                  </carousel>

                </div>

            </v-col>
          </v-row>
        </v-container>

        <div style="position: absolute; top: 40%; left: 50%; transform: translateY(-50%) translateX(-50%)">
          <v-btn outlined dark color="#A06A4C" tile width="300" height="50" to="/tienda" :ripple="false">
            <span class="white--text">Compra en línea</span>
          </v-btn>
        </div>

      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from 'vue-owl-carousel'

export default {
  metaInfo: {
    title: 'Tienda de Tequilas y Mezcales en México | El Murciélago Tequilería',
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: 'es'
    },
    meta: [
      {name:'description', content: 'Tienda con los mejores Tequilas y Mezcales Artesanales en México.'}
    ]
  },
  name: 'Home',
  data () {
    return {
      slider: {},
      instafeed: []
    }
  },
  components: {
    carousel,
  },
  beforeMount: function () {
    const aux = this
    fetch('https://content.elmurcielagotequileria.com/api/singletons/get/SliderHome')
    .then(data => data.json())
    .then(data => aux.slider = data);


    fetch('https://graph.instagram.com/me/media?fields=id,permalink,caption,media_url&access_token=IGQVJYREFTeXhwRmV1LUNlbVJtejMxbjluMVZASM29FczNteEZAIdVF6eGJqYm5ZAME1UYUU4b1J6czh2ZAXpZAMGhYTENZAZAGdyMjRHaXpfbkk3Tk1BV0VqTURMU3ltYjk5N2hxSzNaUHl3')
      .then((data) => data.json())
      .then((data) => this.instafeed = data.data)
  },
  methods: {
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    obtenerHora: function () {
      fetch('https://www.utilidades.elmurcielagotequileria.actstudio.xyz/obtener_hora.php')
      .then(data => data.json())
      .then(data => console.log(data))
    }
  }
}
</script>
